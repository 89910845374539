<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Pagos Anticipados - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
                <b-col sm="12" md="5">
                  <b-form-group label="Socio">
                    <v-select @input="ListLoan" placeholder="Todos" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchPartners" :options="partners"></v-select>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="2">
                  <b-form-group label="Desde :">
                    <b-form-input @change="ListLoan" class="text-center" :max="to" type="date"  ref="to" v-model="from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Selccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="2">
                  <b-form-group label="Hasta :">
                    <b-form-input @change="ListLoan" class="text-center" :min="from" type="date"  ref="from" v-model="to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Selccione una fecha</small>
                  </b-form-group>
                </b-col>
                
                <b-col sm="6" md="2">
                  <b-form-group label=".">
                    <b-input-group>
                    <b-form-input @keyup.enter="ListLoan" v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="ListLoan"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="6" md="1">
                  <b-form-group label=".">
                    <b-link  v-if="Permission('PrepaidAdd')" class="btn form-control btn-primary"  :to="{ path: '/pago-anticipado/nuevo' }" append ><i class="fas fa-file"></i></b-link>
                  </b-form-group>
                </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="7%" class="text-center">Codigo</th>
                    <th width="40%" class="text-center">Socio</th>
                    <th width="10%" class="text-center">Prestamo</th>
                    <th width="10%" class="text-center">Monto</th>
                    <th width="8%" class="text-center">Usuario</th>
                    <th width="8%" class="text-center">Estado</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.payment_date  }}</td>
                    <td class="text-center"> {{ item.code  }}</td>
                    <td class="text-left"> {{ item.name + " "+ item.document_number }}</td>
                    <td class="text-center"> {{ item.number  }}</td>
                    <td class="text-right"> {{ item.total  }}</td>
                    <td class="text-left"> {{ item.user  }}</td>
                    <td class="text-center">
                        <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                        <b-badge v-if="item.state == 1" variant="success">Realizado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('PrepaidEdit')" @click="EditLoan(item.id_prepaid)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('PrepaidView')" @click="ViewLoan(item.id_prepaid)" >Ver</b-dropdown-item>
                        <b-dropdown-item  v-if="Permission('PrepaidDelete')" @click="ConfirmDeleteLoan(item.id_prepaid)" >Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListLoan" :total-rows="rows" :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
export default {
  name: "LoanList",
  components:{
    vSelect,
  },
  data() {
    return {
      module:'Prepaid',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],

      id_partner:'all',
      to:moment(new Date()).local().format("YYYY-MM-DD"),
      from:moment().subtract(30, 'days').local().format("YYYY-MM-DD"),
      search: "",
      partners: [],
      partner:null,
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListLoan();
  },
  methods: {
    ListLoan,
    EditLoan,
    ViewLoan,
    ConfirmDeleteLoan,
    DeleteLoan,
    Permission,

    CodeInvoice,
    SearchPartners,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function SearchPartners(search, loading) {
  let me = this;
  let url = this.url_base + "search-partner/" + search;
  if (search !== "") {
    loading(true);
    axios({
      method: "GET",
      url: url,
      headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
    }).then(function (response) {
          me.partners = response.data;
          loading(false);
    })
  }
}



function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

//listar usuario
function ListLoan() {

  this.errors.from = false;
  this.errors.to = false;

  let id_partner = this.partner == null ? 'all' : this.partner.id;
  if (this.from.length == 0) {this.errors.from = true; return false;}
  if (this.to.length == 0) {this.errors.to = true; return false;}
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "prepaid/list/" + id_partner + "/" + this.from + "/" + this.to + "/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditLoan(id_prepaid) {
  this.$router.push({
    name: "PrepaidEdit",
    params: { id_prepaid: je.encrypt(id_prepaid) },
  });
}

// Ver Usuario
function ViewLoan(id_prepaid) {
  this.$router.push({
    name: "PrepaidView",
    params: { id_prepaid: je.encrypt(id_prepaid) },
  });
}

// Confirmar eliminar
function ConfirmDeleteLoan(id_prepaid) {
  Swal.fire({
    title: "Esta seguro de eliminar el pago anticipado?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteLoan(id_prepaid);
    }
  });
}

// eliminar usuario
function DeleteLoan(id_prepaid) {
  let me = this;
  let url = this.url_base + "prepaid/delete/" + id_prepaid;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token, module: this.module,role: 4,},
  })
    .then(function (response) {
       if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_prepaid).indexOf(response.data.result.id_prepaid);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
